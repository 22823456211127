<template>
	<div style="text-align:right; margin-bottom:5px">
		<button class="p-button p-component p-button-primary btn_success"  @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" >
			<span class="p-button-label">{{ busca_simples_atual_nome }}</span>
			<i class="pi pi-angle-down"></i>
		</button>
		<Menu ref="menu" :model="items2" :popup="true" />
		<Button label="Busca Avançada" class="p-button-primary btn_success" @click="mostrarPesquisa()" />
		<div style="clear:both"></div>
	</div>
	<div class="div_pesquisa" v-if="state.accordion_topo == true">
		
	</div>
	<div class="grid">
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex mb-0">
					<div class="div_img_topo">
						<img src="\images\icones\icone_carrinho.png" class="icone_topo"/>
					</div>
					<div>
						<span class="block text-500 font-medium mb-3">Total de Vendas</span>
						<span class="text-900 font-medium text-xl">{{qtd_total_vendas}}</span> <!--<span class="text-green-500 font-medium">5 novas </span> -->
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex mb-0">
					<div class="div_img_topo">
						<img src="\images\icones\icone_ticket.png" class="icone_topo"/>
					</div>
					<div>
						<span class="block text-500 font-medium mb-3">Ticket Médio</span>
						<span class="text-900 font-medium text-xl">R$ {{ valor_ticket_medio }}</span> <!-- <span class="text-green-500 font-medium">%52+ </span> -->
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex mb-0">
					<div class="div_img_topo">
						<img src="\images\icones\icone_cesta.png" class="icone_topo"/>
					</div>
					<div>
						<span class="block text-500 font-medium mb-3">Produtos Vendidos</span>
						<span class="text-900 font-medium text-xl">{{qtd_produtos_vendidos}}</span> <!-- <span class="text-green-500 font-medium"> +52 novos </span> -->
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div>
					<div style="display: inline-block;">
						<img src="\images\icones\icone_faturamento.png" id="icone_faturamento" />
					</div>
					<div style="display: inline-block; margin-left: 10px;">
						
						<div style="display: inline-block;">
							<span class="block text-500 font-medium mb-3">Faturamento</span>
							<div class="text-900 font-medium text-xl">R$ {{ valor_faturamento }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	<div class="col-12 xl:col-3">
		
		<div class="card">
			<div class="mb-5">
				<div class="grid">
					<div><h5>Acompanhe suas vendas</h5></div>
				</div>
				
			</div>
			<ul class="list-none p-0 m-0">
				<li class="mb-4">
					<div class="div_icone">
						<img src="\images\icones\icone_aprovados.png" class="icone">
					</div>
					<div style="float: left;">
						<span class="numero_minhas_vendas">{{this.qtd_aprovados}}</span><br>
						<span class="status_minhas_vendas">Aprovados</span>
					</div>
					<div class="div_interrogacao">
						<interrogacao-home texto="Vendas aprovadas no período selecionado." />
					</div>
					<div style="clear: both;"></div>
				</li>
				<li class="mb-4">
					<div class="div_icone">
						<img src="\images\icones\icone_faturados.png" class="icone">
					</div>
					<div style="float: left;">
						<span class="numero_minhas_vendas">{{this.qtd_faturados}}</span><br>
						<span class="status_minhas_vendas">Faturados</span>
					</div>
					<div class="div_interrogacao">
						<interrogacao-home texto="Vendas faturadas no período selecionado." />
					</div>
					<div style="clear: both;"></div>
				</li>
				<li class="mb-4">
					<div class="div_icone">
						<img src="\images\icones\icone_enviado.png" class="icone">	
					</div>
					<div style="float: left;">
						<span class="numero_minhas_vendas">{{this.qtd_enviados}}</span><br>
						<span class="status_minhas_vendas">Enviados</span>
					</div>
					<div class="div_interrogacao">
						<interrogacao-home texto="Vendas enviadas no período selecionado." />
					</div>
					<div style="clear: both;"></div>
				</li>
				<li class="mb-4">
					<div class="div_icone">
						<img src="\images\icones\icone_entregues.png" class="icone">
					</div>
					<div style="float: left;">
						<span class="numero_minhas_vendas">{{this.qtd_entregue}}</span><br>
						<span class="status_minhas_vendas">Entregues</span>
					</div>
					
					<div class="div_interrogacao">
						<interrogacao-home texto="Total de venda entregues." />
					</div>
					<div style="clear: both;"></div>
				</li>
				<li class="mb-4">
					<div class="div_icone">
						<img src="\images\icones\icone_cancelados.png" class="icone">
					</div>
					<div style="float: left;">
						<span class="numero_minhas_vendas">{{this.qtd_cancelado}}</span><br>
						<span class="status_minhas_vendas">Cancelados</span>
					</div>
					<div class="div_interrogacao">
						<interrogacao-home texto="Vendas canceladas." />
					</div>
					<div style="clear: both;"></div>
				</li>
			</ul>
		</div>
	</div>

	<div class="col-12 xl:col-9">
		<div class="card">
			<h5>Produtos mais vendidos</h5>
			<DataTable :value="produtos_mais_vendidos" :rows="5" :paginator="true" responsiveLayout="scroll">
				<Column style="width:15%">
					<template #header>
						Imagem
					</template>
					<template #body="slotProps">
						<img :src="slotProps.data.url" :alt="slotProps.nome" width="50" class="shadow-2" />
					</template>
				</Column>
				<Column field="nome" header="Nome" :sortable="true" style="width:35%"></Column>
				<Column style="width:15%" field="qtd" header="Quantidade">
				</Column>
				<Column field="preco_venda" header="Preço Médio" :sortable="true" style="width:35%">
				</Column>
			</DataTable>
		</div>
	</div>
	</div>
</template>

<script>
import Api from '../service/api';
import { reactive } from 'vue';
import EventBus from '@/AppEventBus';
import InterrogacaoHome from './InterrogacaoHome.vue';

export default {
	components: { InterrogacaoHome },
	setup() {
		const state = reactive({
			accordion_topo:false
		})

		return {
			state
		}
	},
	data() {
		return {
			active: false,
			busca_simples_atual: 'mes_atual',
			busca_simples_atual_nome:'Mês Atual',
			busca_topo: '',
			busca_acompanhe_vendas:'',
			qtd_aprovados: '-',
			qtd_faturados: '-',
			qtd_enviados: '-',
			qtd_entregue: '-',
			qtd_cancelado: '-',
			qtd_total_vendas: '-',
			qtd_produtos_vendidos: '-',
			valor_ticket_medio:'-',
			produtos_mais_vendidos: [],
			

			items2: [
				{
					label: 'Hoje',
					command: () => {
						this.alterarBuscaTopo('hoje');
						this.busca_simples_atual = 'hoje'
						this.busca_simples_atual_nome = 'Hoje'

					}
				},
				{
					label: 'Esta Semana',
					command: () => {
						this.alterarBuscaTopo('esta_semana');
						this.busca_simples_atual = 'esta_semana';
						this.busca_simples_atual_nome = 'Esta Semana'
					}
				},
				{
					label: 'Mês Atual',
					command: () => {
						this.alterarBuscaTopo('mes_atual');
						this.busca_simples_atual = 'mes_atual'
						this.busca_simples_atual_nome = 'Mês Atual'
					}
				},
				{
					label: 'Mês Passado',
					command: () => {
						this.alterarBuscaTopo('mes_passado');
						this.busca_simples_atual = 'mes_passado'
						this.busca_simples_atual_nome = 'Mês Passado'
					}
				},
				{
					label: 'Personalizado',
					command: () => {
						this.busca_simples_atual = 'personalizado'
						this.busca_simples_atual_nome = 'Personalizado'
					}
				}
			],
			products: null,
			lineData: {
				labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
				datasets: [
					{
						label: 'Revenue',
						data: [65, 59, 80, 81, 56, 55, 40],
						fill: false,
						backgroundColor: '#2f4860',
						borderColor: '#2f4860',
						tension: 0.4
					},
					{
						label: 'Sales',
						data: [28, 48, 40, 19, 86, 27, 90],
						fill: false,
						backgroundColor: '#00bb7e',
						borderColor: '#00bb7e',
						tension: 0.4
					}
				]
			},
			items: [
                {label: 'Add New', icon: 'pi pi-fw pi-plus'},
                {label: 'Remove', icon: 'pi pi-fw pi-minus'}
            ],
			lineOptions: null,
		}
	},
	themeChangeListener: null,
	created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
		this.api = new Api();        
		
    },
	mounted() {
		//Contagem de Anuncios
		this.quantidades(this.busca_simples_atual);
		this.listaMaisVendidos(this.busca_simples_atual);
		this.pedidosAprovados()


			this.api.qtdPedidosPorStatus(this.token,'FATURADO',this.empresa_id).then(data => {
				this.qtd_faturados = data.data.quantidade
				console.log(data.data.quantidade)
            }); 
			this.api.qtdPedidosPorStatus(this.token,'TRANSPORTADORA',this.empresa_id).then(data => {
				this.qtd_enviados = data.data.quantidade
            }); 
			this.api.qtdPedidosPorStatus(this.token,'CANCELADO',this.empresa_id).then(data => {
				this.qtd_cancelado = data.data.quantidade
            }); 

			//produtos_mais_vendidos

			

	//	this.productService.getProductsSmall().then(data => this.products = data);



		this.themeChangeListener = (event) => {
            if (event.dark)
                this.applyDarkTheme();
            else
                this.applyLightTheme();
        };
        EventBus.on('change-theme', this.themeChangeListener);

		if (this.isDarkTheme()) {
            this.applyDarkTheme();
        }
        else {
            this.applyLightTheme();
        }
	},
	beforeUnmount() {
        EventBus.off('change-theme', this.themeChangeListener );
    },
	methods: {
		toggle(event) {
			this.$refs.menu.toggle(event);
		},
		mostrarPesquisa() {
			if (this.state.accordion_topo) {
				this.state.accordion_topo = false
			} else {	
				this.state.accordion_topo = true
			}
		},
		accordionTopo() {
			if (this.state.accordion_topo == false || this.state.accordion_topo == 1) {
				this.state.accordion_topo = 0;
			} else {
				this.state.accordion_topo = 1;
			}
		},	

		quantidades(tempo) {
			if (tempo != 'personalizado') {
				this.api.qtdUltimosDias(this.token, this.empresa_id, tempo).then(data => {
					if (data.data.verifica_erro == false) {
						this.qtd_total_vendas = data.data.qtd_vendas;
						this.valor_ticket_medio = data.data.ticket_medio;
						this.qtd_produtos_vendidos = data.data.qtd_produtos_vendidos;
						this.valor_faturamento = data.data.faturamento;
						this.qtd_entregue = data.data.qtd_pedidos_entregues;
						this.qtd_cancelado = data.data.qtd_pedidos_cancelados;
					}
				});
			}
		},

		listaMaisVendidos(tempo) {
			this.api.listaProdutosMaisVendidos(this.token, tempo, this.empresa_id).then(data => {
				console.log(data.data)
				if (data.data.erro == false) {
					this.produtos_mais_vendidos = data.data.produtos
				}
			});
		},

		qtdVendidos(tempo) {
			if (tempo != "mes") {
				this.api.qtdProdutosVendidosUltimosDias(this.token, this.empresa_id,tempo).then(data => {
					if (data.data.verifica_erro == false) {
						this.qtd_produtos_vendidos = data.data.quantidade
					}
				}); 	
			} else {
				this.api.qtdProdutosVendidosMes(this.token, this.empresa_id).then(data => {
					if (data.data.verifica_erro == false) {
						this.qtd_produtos_vendidos = data.data.quantidade
					}
				}); 	
			}

		},



		alterarBuscaTopo(tempo) {
			this.quantidades(tempo);
			this.listaMaisVendidos(tempo)
		},
		


		pedidosAprovados() {
			this.api.qtdPedidosPorStatus(this.token, 'APROVADO', this.empresa_id).then(data => {

				this.qtd_aprovados = data.data.quantidade
			}).catch((error) => {
				if (error.response.status == 401) {
					alert('A sessão expirou. Faça Login para voltar ao Hub.');
					this.$router.push('/login');
				} else {
					alert('Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.');
				}
			}); 
		},

		alterarBuscaAcompanheVendas(tempo) {
			this.pedidosCancelados(tempo)
			/*
			this.api.faturamentoMes(this.token, this.empresa_id).then(data => {
				console.log(data.data.verifica_erro)
				if (data.data.verifica_erro == false) {
					this.valor_faturamento = data.data.faturamento
				}
			});
			*/
		},
		
		
		formatCurrency(value) {
			return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
		},
		isDarkTheme() {
            return this.$appState.darkTheme === true;
        },
		applyLightTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
				}
			};
		},
		applyDarkTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#ebedef'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
					y: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
				}
			};
		}
	},
}
</script>
<style scoped lang="css">

.numero_minhas_vendas{
	font-weight: bold;
	font-size:18pt;
}
.status_minhas_vendas{
	color:#747780;
}
.div_icone{
	width:70px;
	text-align:center;
	float: left;
}

.div_interrogacao{
	float: right;
	margin-top: 16px;
	margin-right: 10px;
}

.icone{
	width:45px;
}

.div_img_topo{
	width:70px;
	text-align: left;
}

.icone_topo{
	width:50px;

}
#icone_faturamento{
	width:44px;
}
.div_pesquisa{
	height:100px;
	background-color:#fff;
	transition: 3s;
}
.btn_success{
    background-color:#4e87ee; 
    border:none; 
    margin-right:10px;
	width:160px
}
.btn_success:hover{
    background-color:#4e87ee; 
    border:none; 
    margin-right:10px;
}
.p-button-label{
	font-weight: 700;
}
</style>